@import-normalize; 
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,400&display=swap');

* {
  margin: 0;
  padding: 0;
}

p, button, label, input, textarea {
  font-family: "Inter", sans-serif;
}

h2, h3, h4 {
  font-family: "Satoshi", sans-serif;
}

button {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scroll {
  overflow: hidden;
}
